import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { withMembership } from "../../hoc/withMember"
import { MentorFeedback as Page } from "../../components/Mentor/MentorFeedback"

export const query = graphql`
  query($id: String!) {
    mentor: sanityMentor(_id: { eq: $id }) {
      ...GatsbyMentorFragment
    }
  }
`

export default withAuthentication(withMembership(({ data, ...props }) => <Page {...props} {...data} />))
